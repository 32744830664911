<template>
  <div class="page-final-owner">
    <div class="container-fluid p-initial">
      <div class="row">
        <div class="col-12 col-xxl-8">
          <PageCard :title="t('finalOwner.title')"
                    icon="person">
            <template #content>
              <div class="co-final-owner">
                <div v-if="finalOwner.groups?.length > 0">
                  <div v-for="(group, index) in finalOwner.groups"
                       :key="'group-' + index"
                       class="info-card">
                    <div v-for="(item, itemIndex) in group"
                         :key="'item-' + itemIndex">
                      <div v-if="item.level === 1"
                           class="info-card__header">
                        <div class="icon info-card__header__icon"
                             :class="item.icon.key"
                             :style="item.icon.style" />
                        <h3 class="info-card__header__title">
                          <div v-if="item.url">
                            <a :href="item.url"
                               target="_blank"> {{ item.name }} </a>
                          </div>
                          <div v-else>
                            {{ item.name }}
                          </div>
                        </h3>
                      </div>
                      <div v-if="item.level === 2"
                           class="info-card__content">
                        <img class="info-card__content__icon"
                             src="/assets/icons/icon-final-owner.svg"
                             alt="Icon">
                        <div class="icon"
                             :class="item.icon.key"
                             :style="item.icon.style" />
                        <h3 class="info-card__header__title">
                          {{ item.name }}
                        </h3>
                      </div>
                      <div class="info-card__details"
                           :style="{ 'margin-left': (item.level === 1 ? '3rem' : '5.5rem') }">
                        <div v-if="item.prohibited"
                             style="color: rgb(232, 19, 19)">
                          {{ item.prohibited }}
                        </div>
                        <div v-if="item.address">
                          {{ item.address }}
                        </div>
                        <div v-if="item.percent">
                          {{ item.percent }}
                          <span v-if="item.source" style="color: white">
                            {{ item.source }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div>
                    {{ t('finalOwner.noData1') }}
                  </div>
                  <br />
                  <div>
                    {{ t('finalOwner.noData2') }}
                  </div>
                </div> 
                <hr class="line line--final-owner">
                <div class="row">
                  <div class="col-12">
                    <h3 class="owner-levels-description">
                      {{ t('finalOwner.ownerLevels.title') }}
                    </h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="owner-card">
                      <div class="owner-card__header">
                        <div class="icon building"
                             style="color: #3498DB" />
                        <h4 class="owner-card__header__title">
                          {{ t('finalOwner.ownerLevels.coOwner.title') }}
                        </h4>
                      </div>
                      <div class="owner-card__content">
                        {{ t('finalOwner.ownerLevels.coOwner.description') }}
                      </div>
                    </div>
                    <div class="owner-card">
                      <div class="owner-card__header">
                        <div class="icon profile-user"
                             style="color: #C0C0C0" />
                        <h4 class="owner-card__header__title">
                          {{ t('finalOwner.ownerLevels.owner.title') }}
                        </h4>
                      </div>
                      <div class="owner-card__content">
                        {{ t('finalOwner.ownerLevels.owner.description') }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="owner-card">
                      <div class="owner-card__header">
                        <div class="icon profile-user"
                             style="color: #39B54A" />
                        <h4 class="owner-card__header__title">
                          {{ t('finalOwner.ownerLevels.finalOwner.title') }}
                        </h4>
                      </div>
                      <div class="owner-card__content">
                        <h5 class="owner-card__content__title">
                          {{ t('finalOwner.ownerLevels.finalOwner.list.description') }}
                        </h5>
                        <ul class="owner-card__content__list">
                          <li>{{ t('finalOwner.ownerLevels.finalOwner.list.item-1') }}</li>
                          <li>{{ t('finalOwner.ownerLevels.finalOwner.list.item-2') }}</li>
                          <li>{{ t('finalOwner.ownerLevels.finalOwner.list.item-3') }}</li>
                          <li>{{ t('finalOwner.ownerLevels.finalOwner.list.item-4') }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row"
                     style="margin-top: 20px; font-size: 1.1rem">
                  <div class="col-12">
                    {{ t('finalOwner.warning') }}
                  </div>
                </div>
              </div>
            </template>
          </PageCard>
        </div>
        <div class="col-12 col-xxl-4">
          <DidYouKnow page-name="final-owner"
                      :sticky="true"
                      alternative-card="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, reactive, onMounted, watch, computed } from "vue";
  import { useRoute } from "vue-router";
  import { http, t } from "@/logic";

  const route = useRoute();
  const loading = ref(false);
  const finalOwner = ref({} as any);

  onMounted(async () => {
    await http.fetchWithLoading(finalOwner, loading, "/api/report/" + route.params.id + "/real-owner");		
  });
</script>

<style lang="scss" scoped>
.co-final-owner {
  padding: 2rem;

  & .info-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 0;
    padding: 2rem;
    border: 2px solid $color-border-secondary;
    border-radius: 4px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__header {
      display: flex;
      align-items: center;

      &__title {
        color: $color-font-primary;
        font-weight: 600;
        font-size: 1.4rem;
      }

      &__icon {
        margin-right: 0.75rem;
        font-size: 2.5rem;
      }
    }

    &__content {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 1rem;

      &__title {
        color: $color-font-primary;
        font-weight: 600;
        font-size: 1.4rem;
      }

      & .icon {
        margin-right: 0.5rem;
        margin-left: 3rem;
        color: $color-green;
        font-size: 2.2rem;
      }

      &__icon {
        position: absolute;
        top: 0;
        left: 1rem;
        height: 1.3rem;
      }
    }

    &__details {
      font-size: 1.4rem;
    }
  }

  & .owner-levels-description {
    margin: 1rem 0 2rem;
    color: $color-primary;
    font-weight: 600;
    font-size: 1.4rem;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
    }
  }

  & .owner-card {
    margin-top: 2rem;

    &:first-child {
      margin-top: 0;

      @include media-breakpoint-down(md) {
        margin-top: 2rem;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      min-height: 4rem;

      & .icon {
        margin-right: 0.5rem;
        font-size: 2.2rem;
      }

      &__icon {
        height: 1.8rem;
        margin-right: 0.5rem;
      }

      &__title {
        color: $color-font-primary;
        font-weight: 600;
        font-size: 1.4rem;
        text-transform: uppercase;
      }
    }

    &__content {
      color: $color-font-primary;
      /*font-weight: 600;*/
      font-size: 1.4rem;

      &__title {
        color: $color-font-primary;
        /*font-weight: 600;*/
        font-size: 1.4rem;
      }

      &__list {
        padding: 0;
        list-style-type: none;

        & li {
          position: relative;
          margin-left: 2rem;

          &::before {
            position: absolute;
            left: -1rem;
            margin-right: 0.5rem;
            color: $color-green;
            content: "\00BB";
          }
        }
      }
    }
  }
}
</style>
